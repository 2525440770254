<template>
  <div v-if="logado">
    <admin-toolbar />

    <v-main>
      <router-view :key="`children/${$route.path}`" />
    </v-main>
  </div>
</template>

<script>
import AdminToolbar from "../../components/admin/AdminToolbar.vue";
import api from "../../api";

export default {
  components: {
    AdminToolbar
  },

  data: () => ({
    logado: false
  }),

  mounted() {
    api
      .get("?route=check_token")
      .then(() => (this.logado = true))
      .catch(() => (window.location = "/#/login_admin"));
  },

  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "noindex"
      }
    ]
  }
};
</script>

<style lang="scss" scoped>
.v-main {
  padding-top: 60px !important;
}

.container {
  max-width: 1185px;
}
</style>
