<template>
  <div id="container-admin-toolbar">
    <v-toolbar color="secondary">
      <v-container>
        <v-row>
          <v-col cols="2">
            <div class="d-flex justify-between">
              <v-app-bar-nav-icon large color="white" @click.stop="drawer = !drawer" />
            </div>
          </v-col>
          <v-col cols="10" md="8">
            <div
              class="d-flex justify-center align-center"
              style="color: white; font-weight: bold; height: 100%;"
            >
              <div id="brand" class="mr-5">
                <a href="/#/admin">
                  <v-img id="logo_mobile" contain src="@/assets/logo_mobile.png" alt="Erótica BH" />
                </a>
              </div>
              Área administrativa
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      :temporary="!isDesktop"
      color="secondary"
      app
      :hide-overlay="isDesktop"
      style="padding-top: 8px;"
    >
      <v-list-item tag="a" href="/#/admin/preco">
        Configuração de preço
      </v-list-item>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "AdminToolbar",

  data: () => ({
    drawer: false
  }),

  created() {
    this.drawer = this.isDesktop;
  }
};
</script>

<style lang="scss">
#container-admin-toolbar {
  header {
    z-index: 7;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;

    .v-toolbar__extension {
      position: static;

      .container {
        padding-left: 120px;
      }
    }

    #brand {
      #logo {
        border-radius: 50%;
        width: 98px;
        border: 2px solid #aaa;
      }

      #logo_mobile {
        border-radius: 50%;
        width: 46px;
      }
    }

    .boxSearchDesktop {
      padding-left: 120px;
    }

    a {
      color: white !important;
      text-decoration: none;
    }
  }

  .v-navigation-drawer {
    top: 56px !important;

    a {
      color: white !important;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
    }

    .v-expansion-panel {
      color: white !important;
      font-size: 15px;
      font-weight: 500;
      background-color: transparent !important;

      .v-expansion-panel-content__wrap {
        padding: 0;

        .v-list-item {
          padding-left: 35px;
        }
      }
    }
  }
}
</style>
